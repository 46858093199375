<template>
  <div class="honeycomb d-flex flex-column">
    <div class="container">
      <Breadcrumb
        class="breadcumb--item-color__scoped conteudos_tematicos_section_bc-color"
      >
        <template v-slot:breadcrumbLinks>
          <router-link
            tag="p"
            :to="{ name: link.href }"
            v-for="(link, idx) in breadcrumbItems"
            :key="idx"
          >
            {{ link.text }}
          </router-link>
        </template>
      </Breadcrumb>
    </div>
    <section>
      <v-row class="justify-center align-center">
        <v-col class="image__title d-flex justify-center align-start pt-8">
          <img
            width="40%"
            src="../../assets/conteudos_ser_atingido_title.png"
            alt=""
          />
        </v-col>
      </v-row>
    </section>
    <div class="container d-flex flex-column pt-10">
      <section class="d-flex justify-center align-center flex-column">
        <v-row class="pos-relative image__links top-10 ml15">
          <img
            @click="
              flyToPage('conteudos_tematicos_ser_atingido_trabalho_atingidos')
            "
            src="./assets/title_trabalho.png"
            alt=""
            class="align-self-start align-self-md-center"
          />
          <img
            @click="flyToPage('conteudos_tematicos_ser_atingido_discriminacao')"
            src="./assets/title_discriminacao.png"
            alt=""
            class="aux-top-15"
          />
        </v-row>
        <v-row class="pos-relative image__links ml15">
          <img
            @click="flyToPage('conteudos_tematicos_ser_atingido_oque_e')"
            src="./assets/title_oquee.png"
            alt=""
            class="align-self-start align-self-md-center aux-top-20"
          />

          <img
            @click="flyToPage('conteudos_tematicos_ser_atingido_estigma')"
            src="./assets/title_estigma.png"
            alt=""
            class="align-self-center aux-top-30"
          />

          <img
            @click="flyToPage('conteudos_tematicos_ser_atingido_idosos')"
            src="./assets/title_idosos.png"
            alt=""
            class="align-self-start align-self-md-center aux-top-40"
          />
        </v-row>
        <v-row
          class="pos-relative negative-top-lc image__links aux-top-20 ml15"
        >
          <img
            @click="
              flyToPage('conteudos_tematicos_ser_atingido_nao_reconhecidos')
            "
            src="./assets/title_naoreconhecidos.png"
            alt=""
            class="aux-top-10"
          />
          <img
            @click="flyToPage('conteudos_tematicos_ser_atingido_tempos')"
            src="./assets/title_tempos.png"
            alt=""
            class="align-self-start align-self-md-center aux-top-15"
          />
        </v-row>
      </section>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/layout/Breadcrumb.vue";
export default {
  components: { Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Ser Atingido /",
          href: "conteudos_tematicos_ser_atingido",
        },

        {
          text: "Início",
          href: "conteudos_tematicos_ser_atingido",
        },
      ],
    };
  },
  methods: {
    flyToPage(path) {
      this.$router.push({ name: path });
    },
  },
};
</script>

<style scoped>
.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.negative-top {
  top: -6%;
  left: -7%;
}

.negative-top-lc {
  top: -12%;
}
.honeycomb {
  height: 100%;
  width: 100%;
  background-image: url("../../assets/conteudos_ser_atingido_background.png");
  background-size: cover;
  display: flex;
  background-color: #f9f7ed;
}

.image__links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-10 {
  top: 10%;
}

.image__links img {
  width: 25%;
  cursor: pointer;
}

.image__title img {
  max-width: 30%;
}

@media (max-width: 600px) {
  .image__links {
    flex-direction: column;
  }
  .image__links img {
    width: 70%;
    padding: 1em;
  }
  .negative-top {
    top: 0;
    left: 0;
  }

  .negative-top-lc {
    top: 0;
  }

  .top-10 {
    top: 0;
  }

  .aux-top-15 {
    position: relative;
    top: -15%;
  }

  .aux-top-20 {
    position: relative;
    top: -20%;
  }

  .aux-top-30 {
    position: relative;
    top: -30%;
  }

  .aux-top-40 {
    position: relative;
    top: -40%;
  }

  .ml15 {
    margin-left: 15%;
  }
}

@media (max-width: 400px) {
  .image__links img {
    width: 60%;
  }

  .image__title img {
    max-width: 100%;
  }
}
</style>
